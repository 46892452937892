export default [
  {
    key: "full_name",
    label: "Employee",
  },
  {
    key: "know_as",
    label: "Known as",
  },
  {
    key: "department",
    label: "Department",
  },
  {
    key: "qr_generator_count",
    label: "QR Count",
    class: "text-nowrap",
  },
  {
    key: "actions",
    label: "Actions",
  },
]