<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
    >
      <template #buttons>
        <b-button
          variant="success"
          class="ml-1"
          :disabled="!thereAreEmployeesSelected"
          @click="openGenerateQrCodeModal()"
        >
          <tabler-icon
            icon="BarcodeIcon"
            class="mr-50"
            size="20"
          />Generate QR
          Code {{ countEmployeesSelected }}
        </b-button>
      </template>
      <template #table>
        <b-table
          ref="qrGeneratorRrhh"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #head(selected)>
            <b-form-checkbox
              v-model="allEmployeesAreSelected"
              @input="selectAllEmployees"
            />
          </template>
          <template #cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="employeesSelectedForQr"
                :value="data.item"
                :name="data.item.account_id"
                class="mt-1"
              />
            </b-form-group>
          </template>
          <template v-slot:cell(id)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <span>
                {{ data.item.pid }}
              </span>
            </div>
          </template>
          <template v-slot:cell(first_name)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start text-nowrap"
            >
              <span>
                {{ data.item.first_name }}
              </span>
            </div>
          </template>
          <template v-slot:cell(last_name)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start text-nowrap"
            >
              <span>
                {{ data.item.last_name }}
              </span>
            </div>
          </template>
          <template v-slot:cell(know_as)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <span>
                {{ data.item.know_as }}
              </span>
            </div>
          </template>
          <template v-slot:cell(dob)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <span>
                {{ data.item.dob | myGlobal }}
              </span>
            </div>
          </template>
          <template v-slot:cell(mobile)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start text-nowrap"
            >
              <span>
                {{ data.item.mobile }}
              </span>
            </div>
          </template>
          <template v-slot:cell(rol)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <span>
                {{ data.item.rol }}
              </span>
            </div>
          </template>
          <template v-slot:cell(start_date)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start text-nowrap"
            >
              <span>
                {{ data.item.start_date | myGlobal }}
              </span>
            </div>
          </template>
          <template v-slot:cell(qr_generator_count)="data">
            <div
              class="text-center text-nowrap"
              @click="openTrackingQrCodeModal(data.item.id)"
            >
              <span
                class="cursor-pointer"
                :style="{
                  letterSpacing: '1px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  backgroundColor:
                    data.item.qr_generator_count > 0 ? '#0080FF' : '#F1C40F',
                  color: '#FFFFFF',
                  textAlign: 'center',
                  lineHeight: '25px',
                  boxShadow:
                    data.item.qr_generator_count > 0
                      ? '0px 3px 5px 3px rgba(0, 0, 0, 0.2)'
                      : '',
                }"
              >
                {{ data.item.qr_generator_count }}
              </span>
            </div>
          </template>
          <template v-slot:cell(user_name)="data">
            <span>{{ data.item.user_name }}</span>
            <br>
            <span>{{ data.item.date_created }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <generate-qr-code-modal
      v-if="generateQrCodeModalOpened"
      :employees-list="employeesSelectedForQrSimplified"
      @close="closeGenerateQrCodeModal"
    />
    <generate-qr-code-modal-tracking
      v-if="trackingQrCodeModalOpened"
      :employee-id="employeeId"
      @close="closeTrackingQrCodeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fieldsData from '@/views/assistance/views/qr-generator/data/qr-generator.fields.data';
import filtersData from '@/views/assistance/views/qr-generator/data/qr-generator.filters.data';
import EmployeesService from '@/views/management/views/employees/employees.service';
import GenerateQrCodeModal from '@/views/assistance/views/qr-generator/modals/GenerateQrCodeModal.vue';
import GenerateQrCodeModalTracking from '@/views/assistance/views/qr-generator/modals/GenerateQrCodeModalTracking.vue';

export default {
  components: {
    GenerateQrCodeModal,
    GenerateQrCodeModalTracking,
  },
  data() {
    return {
      basicSearch: true,
      employeeId: null,
      employees: [],
      fields: fieldsData,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      filters: filtersData,
      generateQrCodeModalOpened: false,
      isBusy: false,
      items: [],
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      allEmployeesAreSelected: false,
      employeesSelectedForQr: [],
      startPage: null,
      trackingQrCodeModalOpened: false,
      toPage: null,
      totalRows: 0,
    };
  },
  async created() {
    this.addPreloader();
    await this.getDepartments();
    this.removePreloader();
  },
  methods: {
    async myProvider() {
      this.allEmployeesAreSelected = false;
      this.employeesSelectedForQr = [];

      const params = {
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        text: this.filterPrincipal.model,
        from: this.filters[0].model,
        to: this.filters[1].model,
        departament: this.filters[2].model,
        has_qr_code: this.filters[3].model,
      };
      try {
        const data = await EmployeesService.getActiveEmployeesWithIdUser(
          params,
        );

        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        this.employees = data.data.data;

        return this.employees || [];
      } catch (err) {
        console.error('Failed to get tolerance time rules', err);
      }
    },
    async getDepartments() {
      try {
        const { data: deparments } = await EmployeesService.getAllDepartments();
        this.filters[2].options = deparments;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    selectAllEmployees() {
      this.employeesSelectedForQr = this.allEmployeesAreSelected
        ? this.employees.slice()
        : [];
    },
    openGenerateQrCodeModal() {
      this.generateQrCodeModalOpened = true;
    },
    closeGenerateQrCodeModal(success) {
      if (success) {
        this.refreshTable();
      }
      this.generateQrCodeModalOpened = false;
    },
    openTrackingQrCodeModal(id) {
      this.employeeId = id;
      this.trackingQrCodeModalOpened = true;
    },
    closeTrackingQrCodeModal() {
      this.employeeId = null;
      this.trackingQrCodeModalOpened = false;
    },
    refreshTable() {
      this.allEmployeesAreSelected = false;
      this.employeesSelectedForQr = [];
      this.$refs.qrGeneratorRrhh.refresh();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    employeesSelectedForQrSimplified() {
      return this.employeesSelectedForQr.map(employee => ({
        id: employee.id,
        pid: employee.pid,
        full_name: `${employee.first_name.split(' ')[0]} ${
          employee.last_name.split(' ')[0]
        }`,
        know_as: employee.know_as,
        department: employee.department,
        qr_generator_count: employee.qr_generator_count,
      }));
    },
    thereAreEmployeesSelected() {
      return this.employeesSelectedForQr.length > 0;
    },
    countEmployeesSelected() {
      return this.employeesSelectedForQr.length > 0
        ? `(${this.employeesSelectedForQr.length})`
        : '';
    },
  },
};
</script>

  <style scoped>
</style>
