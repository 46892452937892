export default [
  {
    key: 'selected',
    label: '',
    visible: true,
  },
  {
    key: 'pid',
    sortable: false,
    label: 'N Doc',
  },
  {
    key: 'first_name',
    sortable: false,
    label: 'First Name',
  },
  {
    key: 'last_name',
    sortable: false,
    label: 'Last Name',
  },
  {
    key: 'know_as',
    sortable: false,
    label: 'Know as',
  },
  {
    key: 'mobile',
    sortable: false,
    label: 'Mobile',
  },
  {
    key: 'department',
    sortable: false,
    label: 'Department',
  },

  {
    key: 'rol',
    sortable: false,
    label: 'Rol',
  },
  {
    key: 'start_date',
    sortable: false,
    label: 'Start Date',
  },
  {
    key: 'user_name',
    sortable: false,
    label: 'Created By',
    class: 'text-center',
  },
  {
    key: 'qr_generator_count',
    label: 'QR Count',
    class: 'text-nowrap',
  },
  // {
  //     key: "actions",
  //     label: "Actions",
  // },
];
