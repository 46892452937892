<template>
  <b-modal
    centered
    title="QR Code Generator History"
    ok-title="Send Request"
    v-model="modalUp"
    size="md"
    hide-footer
    @hidden="hiddenModal()"
  >
    <b-row>
      <b-col>
        <b-table
          ref="tracking-table"
          small
          :items="myProvider"
          :fields="fields"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(know_as)="data">
            {{ capitalizeNames(data.item.know_as) }}
          </template>
          <template #cell(created_by)="data">
            {{ data.item.created_by }} <br />
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>
  
<script>
import { mapGetters } from "vuex";
import Fields from "@/views/assistance/views/qr-generator/data/qr-code-modal-tracking.fields.data.js";
import EmployeeQrGenerationService from "@/views/assistance/views/qr-generator/employee-qr-generation.service.js";

export default {
  props: {
    employeeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    fields: Fields,
    isLoading: false,
    modalUp: true,
  }),
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const { data } =
          await EmployeeQrGenerationService.getQrEmployeeTracking({
            employee_id: this.employeeId,
          });
        this.removePreloader();
        return data;
      } catch (err) {
        console.error(err);
        this.removePreloader();
      }
    },
    hiddenModal() {
      this.$emit("close");
    },
    capitalizeNames(name) {
      return name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
  },
  watch: {},
};
</script>
  
  <style scoped>
</style>
  