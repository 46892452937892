<template>
  <b-modal
    v-model="modalState"
    scrollable
    title-class="h3 text-white font-weight-bolder text-center"
    size="lg"
    title="Employees"
    @hidden="close()"
  >
    <b-row>
      <b-col>
        <b-table
          small
          show-empty
          :fields="fields"
          :items="locaEmployeesList"
          class="font-small-3 text-center"
          sticky-header="50vh"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(full_name)="data">
            {{ data.item.full_name }}
          </template>
          <template #cell(know_as)="data">
            {{ capitalizeNames(data.item.know_as) }}
          </template>
          <template #cell(actions)="data">
            <tabler-icon
              class="text-danger cursor-pointer"
              icon="TrashIcon"
              size="20"
              @click="removeEmployee(data.item.pid)"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        :disabled="!thereAreEmployeesSelected"
        @click="generateEmployeeQrCode()"
      >
        CONFIRM
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import EmployeesService from '@/views/management/views/employees/employees.service';
import Fields from '@/views/assistance/views/qr-generator/data/generate-qr-code-modal.fields.data';

export default {
  components: {},
  props: {
    employeesList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      modalState: true,
      locaEmployeesList: this.employeesList,
      fields: Fields,
      isBusy: false,
      selectAgent: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    thereAreEmployeesSelected() {
      return this.locaEmployeesList.length > 0;
    },
  },
  methods: {
    close(success = false) {
      this.$emit('close', success);
    },
    removeEmployee(pid) {
      this.locaEmployeesList = this.locaEmployeesList.filter(
        employee => employee.pid !== pid,
      );
    },
    capitalizeNames(name) {
      if (!name) return '';
      return name
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    async generateEmployeeQrCode() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      this.addPreloader();

      const todayDate = new Date().toLocaleDateString('en-US');

      // Helper function to download a blob
      const downloadBlob = (blob, fileName) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      };

      // Use a for...of loop with await
      for (const employee of this.locaEmployeesList) {
        const response = await EmployeesService.generateEmployeeQrCode({
          employee_id: employee.id,
          pid: employee.pid,
          user_id: this.currentUser.user_id,
        });
        const fileName = `QR_${employee.full_name}_${todayDate}.pdf`;

        // Convert the response data to a blob and download it
        const blob = new Blob([response.data], { type: 'application/pdf' });
        downloadBlob(blob, fileName);
      }

      this.removePreloader();
      this.close(true);
    },
  },
};
</script>
<style>
.padding-y {
  padding: 5px 0 !important;
}
</style>
