/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

// General services on module MetaMedia after refactoring
class EmployeeQrGenerationService {
  async getQrEmployeeTracking(params) {
    try {
      const data = await amgApi.post('/attendance/get-qr-employee-tracking', params);
      return data;
    } catch (error) {
      console.log('Something went wrong on getQrEmployeeTracking:', error);
      throw error;
    }
  }
}

export default new EmployeeQrGenerationService();
