export default [
    {
        type: 'datepicker',
        margin: true,
        showLabel: true,
        label: 'From',
        placeholder: 'Date',
        class: 'font-small-3',
        model: null,
        locale: 'en',
        dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
        cols: 6,
        visible: true,
    },
    {
        type: 'datepicker',
        margin: true,
        showLabel: true,
        label: 'To',
        placeholder: 'Date',
        class: 'font-small-3',
        model: null,
        locale: 'en',
        dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
        cols: 6,
        visible: true,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Department',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'name',
        cols: 12,
        visible: true,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Has QR Code',
        model: null,
        options: [
            { id: 1, name: 'Yes' },
            { id: 0, name: 'No' }
        ],
        reduce: 'id',
        selectText: 'name',
        cols: 12,
        visible: true,
    },
]
